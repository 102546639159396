import './App.css';
import { useState } from "react";

export default function App() {
  const [textAreaContent, setTextAreaContent] = useState(
    "I have a small company, Ben Co., that is making a few marketing pages. We only have a few hundred clients but we have a smart team of ambitious and talented engineers. We'd like our pages to load faster than our competitors who have pretty fast pages. We don't need to personalize our data."
  );
  const getContent = (text) => {
    const content = `Act like an advisor giving a client a recommendation for picking the right type of rendering strategy to use to build their frontend.
  
  ${text}
  
  Use the following information and matrix to answer this question: What kind of rendering strategy should I use?
  
  Follow up your analysis with: "For a more detailed analysis of your business, the tools you can use, and where to look for more information contact Rangle.io!"
  
  ---
  
  Now that we understand that content is served from a Headless CMS database to a front end presentation layer, the question of ***how to build the presentation layer*** still exists. This is where a Static Site Generator (SSG) can come into play. Before we get into how to leverage a SSG to build the front end, let’s take a closer look at what a static site is, along with some other key terms.
  
  ### Static Site Generation (SSG)
  
  In its most basic form, SSG uses markdown and templates to generate pages of a website. This is similar to a lot of dynamic web programming – the main difference being that with SSG the pages are generated and content is bundled at ***build*** time. This means that the site is pre-rendered on the server and a fully rendered page is sent to the client to display. This makes the site faster, more secure, and more reliable compared to traditional webpages that are rendered when they are requested by the client. SSG sites often use APIs or frameworks like Next.js and Gatsby to handle client-side JavaScript and make the statically rendered pages dynamic, turning the site into a single-page application (SPA).
  
  ### Server Side Rendering (SSR)
  
  A site that has server side rendering means that a server-side application is responsible for rendering pages on demand, while the browser simply displays the content. The web page is displayed on the website’s server, and the fully rendered page is sent to the client where the client’s JavaScript bundle will engage and initiate the SPA framework to make the site dynamic.
  
  ### Incremental Static Regeneration (ISR)
  
  Incremental Static Regeneration is a mix of these two approaches aimed at large sites where individual pages change often. The server hosting the website builds each page that is requested and saves that generated static page for later use. Any users requesting that page will receive the static page if it has already been generated. However, if the team maintaining the website wants to update any of these saved pages, they can “invalidate” any page and the server will rebuild it when that page is next requested.
  
  ### Picking the Right Option
  
  With so many options having a deep set of features it’s difficult to determine which one best fits your situation. Here are the key factors that should be evaluated when picking an option for your team:
  
  |  | Static Site Generation (SSG) | Server Side Rendering (SSR) | Incremental Static Regeneration (ISR) |
  | --- | --- | --- | --- |
  | Easiest to configure | ✅ | ❌ | ❌ |
  | Best at handling many concurrent requests | ✅ | ❌ | ✅ |
  | Fastest Page Load Time | ✅ | ❌ | ✅ |
  | Fastest Page Rebuild | ❌ | ✅ | ✅ |
  | Able to personalize content for the user | ❌ | ✅ | ❌ |
  
  ### Examples from the Field: Medium Sized E-Commerce
  
  A client has an e-commerce site with over a hundred pages that serves hundreds of thousands of customers per month. Their development team is eager to learn new things and wants to dive into challenging problems. They don’t have a lot of personalization yet on their pages, but hope to have this in the future.
  
  What kind of site rendering should we use?
  
  - Given the type of team the client has, easiest to configure isn’t our highest priority. That being said, SSG would be easy to launch quickly and perhaps we should ask about the urgency to release the site and if we have the time for something more complex.
  - The client has a lot of customers hitting their servers so we need to handle a lot of concurrent requests. This points us to SSG or ISR.
  - We want to ensure we compete well for our SEO with good Web Vitals and so we can draw in more customers. We want to have fast Page Load Times and SSG and ISR are the best at that.
  - Fast page rebuild could be really valuable for this client and might be the tie-breaker between SSG and ISR. Evaluate the page build speeds and see if they are ok with products promoting the wrong price or incorrectly showing if the products are in-stock while a build is processing.
  
  If they have time to build, then go SSR, otherwise ISR might be the right call to quickly update products.
  - Since it sounds like they don’t need personalization right away but want it in the future, it might make a lot of sense to use ISR. This will get them comfortable with deploying and managing a server while also allowing them to switch to SSR when they are ready.
  
  Overall, it looks like this client should be using **Incremental Static Regeneration (ISR)** because of it’s ability to quickly update product pages, because their team is excited about new challenges, and because they can transition to SSR when they are ready to do so for personalization.`;

    // Copy the text inside the text field
    navigator.clipboard.writeText(content);
  };

  return (
    <div className="App">
      <h1>What CMS Rendering Strategy is Best for me?</h1>
      <p>
        If you've reviewed all of the rendering options for how to render your
        Headless Architecture but still aren't sure what to use, let's put
        together a prompt that you can use to get more answers using ChatGPT.
      </p>

      <label for="query">
        <p>Tell us about your business:</p>
        <ul>
          <li>
            What does your company do and what kind of website do you have?
            (Example: Ecommerce)
          </li>
          <li>How many visitors do you get to your site per month?</li>
          <li>
            How frequently do you update your site? How fast does it need to
            rebuild?
          </li>
          <li>
            How many developers do you have? Are they highly skilled or
            motivated?
          </li>
        </ul>
      </label>
      <textarea
        id="query"
        name="query"
        rows="5"
        onChange={(content) => setTextAreaContent(content.target.value)}
      >
        {textAreaContent}
      </textarea>

      <button onClick={() => getContent(textAreaContent)}>
        Copy Prompt to Clipboard for ChatGPT
      </button>
      <br />

      <a href="https://chat.openai.com/chat">Go To ChatGPT</a>
    </div>
  );
}
